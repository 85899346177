<template>
  <div class="row">
    <div class="col-md-12" v-if="loading">
      <div class="row mt-5 pt-5">
        <div class="col-md-12" v-loading="loading"></div>
      </div>
    </div>
    <div class="col-md-12 my-4" v-if="!loading">
      <div class="d-flex justify-content-end">
        <div class="form_contain">
          <el-input
            placeholder="search by number or transaction id"
            class="search_item"
            style="height: 40px !important"
            v-model="search"
            :debounce="2000"
            :disabled="text_loading"
            @input="debounceSearch"
            autocomplete="on"
            clearable
            @clear="fetchWalletHistory(1)">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="!loading">
      <el-table
        :data="tableData"
        :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
        style="width: 100%"
        :empty-text="'No Data Available'"
        height="55vh">
        <el-table-column label="Transaction ID" prop="ref_id">
        </el-table-column>
        <el-table-column label="Amount" prop="amount">
          <template slot-scope="scope">
            {{ scope.row | currency("amount") }}
          </template>
        </el-table-column>
        <el-table-column label="Number" prop="momo_num"> </el-table-column>
        <el-table-column label="Payment Date">
          <template slot-scope="scope">
            {{ moment(scope.row.payment_date).format("LL") }}
          </template>
        </el-table-column>
        <el-table-column
          label="Balance Before Deposit"
          prop="balance_before_deposit"
          width="200">
        </el-table-column>
        <el-table-column
          label="Balance Before Deposit"
          prop="balance_before_deposit"
          width="200">
          <template slot-scope="scope">
            {{ scope.row | currency("balance_before_deposit") }}
          </template>
        </el-table-column>
        <el-table-column label="Status" prop="status_message">
          <template slot-scope="scope">
            <el-tag
              v-if="
                scope.row.status_message.toLowerCase().includes('completed')
              "
              type="success">
              {{ scope.row.status_message }}
            </el-tag>
            <el-tag
              v-else-if="
                scope.row.status_message.toLowerCase().includes('pending')
              "
              type="warning">
              {{ scope.row.status_message }}
            </el-tag>
            <el-tag
              v-else-if="
                scope.row.status_message.toLowerCase().includes('failed')
              "
              type="danger">
              {{ scope.row.status_message }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="Payment Method"
          prop="payment_method"></el-table-column>
        <el-table-column label="Top up by" prop="top_up_by"></el-table-column>
      </el-table>
    </div>
    <div class="col-md-12" style="text-align: center; margin-top: 2em">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import appConfig from "@/app.config";
  import _ from "lodash";

  export default {
    page: {
      title: "Wallet history",
      meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
      return {
        loading: false,
        tableData: [],
        page: 1,
        pageSize: 50,
        total: 0,
        search: "",
        text_loading: false,
      };
    },
    mounted() {
      this.fetchWalletHistory(this.page);
    },
    methods: {
      handleCurrentChange(val) {
        this.fetchWalletHistory(val);
      },
      fetchWalletHistory(page) {
        this.loading = true;
        store
          .dispatch("admin/walletHistoryFr/getWalletHistoryFr", {
            page: page,
          })
          .then((res) => {
            this.tableData = res.data;
            this.total = res.total;
          })
          .finally(() => (this.loading = false));
      },
      searchTable() {
        this.loading = true;
        this.text_loading = true;
        store
          .dispatch("admin/walletHistoryFr/searchWalletHistory", {
            search: this.search,
          })
          .then((res) => {
            this.tableData = res.data.data;
            this.total = res.total;
          })
          .finally(() => {
            this.loading = false;
            this.text_loading = false;
          });
      },
      debounceSearch: _.debounce(function () {
        if (this.search.length < 3) return;
        this.searchTable();
      }, 2000),
    },
    filters: {
      currency(row, key) {
        if (!row) return "";
        if (row.payment_method == "Paydunya") {
          // return amount formatted into CFA
          return row[key].toLocaleString("fr-FR", {
            style: "currency",
            currency: "CFA",
          });
        }
        return row[key].toLocaleString("en-US", {
          style: "currency",
          currency: "GHS",
        });
      },
    },
  };
</script>

<style></style>
